
<template>
    <div style="width:320px;height:560px">
    <!-- canvas渲染方式 -->
    <pdf url="https://ebook.t.educg.com/fileDownloadPDF.out?fid=64b8b173a2f25a13be7fa228" type="canvas" />
    <!-- svg渲染方式 -->
    <pdf url="https://ebook.t.educg.com/fileDownloadPDF.out?fid=64b8b173a2f25a13be7fa228" type="svg" />
</div>
</template>

<script>
import pdf from "@/components/pdf/pdf.js";
export default {
    name:'',
    components:{
        pdf 
    }
}
</script>